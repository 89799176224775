@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Raleway:ital,wght@0,300;0,400;0,800;1,300&display=swap');
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  transition: all;
  transition-duration: 1s;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

